<template>
  <div>
    <div class="header">
      <div class="search">
        <a-button
          type="primary"
          class="header-item"
          @click="showPost"
          size="small"
        >
          <a-icon type="left" />返回帖子列表</a-button>
      </div>
      <div class="add-btn">
        <a-button type="primary" @click="addComment" size="small">
          新增评论</a-button
        >
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      @change="changePage"
      rowKey="id"
    >
      <!-- 二级tab -->
      <template slot="expandedRowRender" slot-scope="row">
        <a-table
          :columns="innerColumns"
          rowKey="id"
          :data-source="row.subComments"
          :pagination="false"
        >
          <span slot="action" slot-scope="row2">
            <a class="action-item" @click="twoComment(row, row2)">回复</a>
            <a class="action-item" @click="editComment(row2)">编辑</a>
            <a class="action-item" @click="deleteComment(row2)">删除</a>
          </span>
        </a-table>
      </template>
      <!-- 操作 -->
      <span slot="action" slot-scope="row">
        <a class="action-item" @click="oneComment(row)">回复</a>
        <a class="action-item" @click="editComment(row)">编辑</a>
        <a class="action-item" @click="deleteComment(row)">删除</a>
      </span>
    </a-table>

    <!-- 评论框 -->
    <a-modal
      v-model="commentVisible"
      ok-text="确认"
      cancel-text="取消"
      :title="commentTitle"
      @ok="confirmComment"
    >
      <div class="model-item">
        <div class="left">用户:</div>
        <a-select
          style="width: 150px"
          size="small"
          ref="addse"
          v-model="selectUserId"
          placeholder="选择用户"
          allowClear
        >
          <a-select-option
            v-for="item of userList"
            :key="item.id"
            :value="item.id"
            >{{ item.userName }}</a-select-option
          >
        </a-select>
      </div>
      <div class="model-item">
        <div class="left">内容:</div>
        <a-textarea
          v-model="commentContent"
          placeholder="请输入评论内容"
          autoSize
          allow-clear
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "内容",
    dataIndex: "content",
    key: "content",
  },
  {
    title: "用户名",
    dataIndex: "userName",
    key: "userName",
  },

  {
    title: "评论时间",
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  props: ["detail"],
  data() {
    return {
      type: 0,
      searchParams: {
        pageNum: 1,
        pageSize: 10,
      },
      list: [],
      columns,
      commentVisible: false,
      innerColumns: columns,
      commentContent: "",
      commentType: 0,
      commentTitle: "",
      selectUserId: "",
      oneLevelCommentId: "", // 一级id
      replyCommentId: "", // 二级id
      userList: [], //用户列表
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
    };
  },
  watch: {
    commentVisible(val) {
      if (!val) {
        this.selectUserId = "";
        this.commentContent = "";
      }
    },
  },
  mounted() {
    this.getCommentList();
    this.getUserList();
  },
  methods: {
    // 编辑评论
    editComment(row) {
      this.type = 1;
      this.selectUserId = row.userId;
      this.commentContent = row.content;
      this.commentId = row.id;
      this.commentVisible = true;
      this.commentTitle = "编辑评论";
    },
    // 运营用户列表
    async getUserList() {
      const res = await this.axios("/dq_admin/bbsPostComment/joinComentUsers");
      if (res.status != 200) return;
      this.userList = res.data;
    },
    // 删除评论
    async deleteComment(row) {
      const { id } = row;
      this.$confirm({
        title: "确定要删除该评论吗?",
        content: "",
        okText: "确定",
        cancelText: "取消",
        onOk: async () => {
          const res = await this.axios("/dq_admin/bbsPostComment/delById", {
            params: {
              id,
            },
          });
          if (res.status != 200) return;
          this.$message.success("删除成功");
          this.getCommentList();
        },
        onCancel: function onCancel() {},
      });
    },
    // 二级
    twoComment(row, row2) {
      this.commentType = 2;
      this.commentTitle = "回复";
      this.oneLevelCommentId = row.id;
      this.replyCommentId = row2.id;
      this.type = 0;
      this.showCommentModal();
    },
    // 一级评论
    oneComment(row) {
      const { id } = row;
      this.commentType = 1;
      this.commentTitle = "回复";
      this.oneLevelCommentId = id;
      this.replyCommentId = id;
      this.type = 0;
      this.showCommentModal();
    },
    // 新增评论
    addComment() {
      this.commentType = 0;
      this.commentTitle = "新增评论";
      this.type = 0;
      this.showCommentModal();
    },
    // 提交评论
    async confirmComment() {
      if (!this.selectUserId) return this.$message.error("请选择评论用户");
      if (!this.commentContent) return this.$message.error("请输入评论内容");
      const obj = {};
      obj["userId"] = this.selectUserId;
      obj["postId"] = this.detail.id;
      switch (this.commentType) {
        case 1:
          obj["oneLevelCommentId"] = this.oneLevelCommentId;
          obj["replyCommentId"] = this.replyCommentId;
          break;
        case 2:
          obj["oneLevelCommentId"] = this.oneLevelCommentId;
          obj["replyCommentId"] = this.replyCommentId;
          break;
        default:
          break;
      }
      // type == 1编辑 0,新增
      if (this.type == 1) obj["id"] = this.commentId;
      const res = await this.axios.post("/dq_admin/bbsPostComment/addOrEdit", {
        ...obj,
        content: this.commentContent,
      });
      if (res.status != 200) return;
      this.commentVisible = false;
      this.selectUserId = "";
      this.commentContent = "";
      this.getCommentList();
      // dq_admin/bbsPostComment/addOrEdit
    },

    // 显示评论框
    showCommentModal() {
      this.commentVisible = true;
    },
    // 评论列表
    async getCommentList() {
      const res = await this.axios("/dq_admin/bbsPostComment/list", {
        params: {
          postId: this.detail.id,
          ...this.searchParams,
        },
      });
      if (res.status != 200) return;
      this.list = res.data.records;
      this.pagination.total = res.data.total;
    },

    // 分页
    changePage(page) {
      const { current, pageSize } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.searchParams, "pageNum", current);
      this.getCommentList();
    },
    // 查看评论
    showPost() {
      this.$emit("change");
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.header-item {
  margin: 0 10px 10px 0;
  &.width {
    width: 150px;
  }
}
.action-item {
  margin-right: 8px;
}
.model-item {
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin-bottom: 8px;
  .left {
    margin-right: 8px;
  }
}
</style>